import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { DepositWithdrawPanel, StrategyInfo } from 'pages/dashboard/components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { activePoolActions } from 'store/active-pool';
import { sMMPools } from 'data';
import { Box, Button, GlobalStyles, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { MenuBar } from 'pages/dashboard/components/MenuBar';
import { Gutter } from 'components/Gutter';
import Combination from 'assets/images/combination.png';
import HyperliquidT from 'assets/images/hyperliquidT.png';
import { Benefits } from 'pages/dashboard/components/Benefits';

const PageLayout = styled(Box)`
	display: grid;
	grid-template-columns: 270px auto 350px;
	grid-auto-rows: min-content;
	column-gap: 30px;
	row-gap: 40px;
	min-height: 95vh;
	max-width: 100%;

	grid-template-areas:
		'Menu Heading Panel' 'Menu Benifit Panel' 'Menu Ganesh Panel' 'Menu Stats Panel' 'Menu Charts Panel'
		'Menu DocPanel Panel';

	@media (max-width: 1350px) {
		grid-template-columns: auto 350px;
		grid-template-rows: min-content min-content min-content min-content;
		grid-template-areas: 'Menu Menu' 'Heading Panel' 'Menu Benifit Panel' 'Stats Panel' 'Charts Panel' 'DocPanel Panel' 'Ganesh Panel';
	}

	@media (max-width: 1200px) {
		grid-template-columns: auto 350px;
		grid-template-rows: min-content min-content min-content min-content;
		grid-template-areas: 'Menu Menu' 'Heading Panel' 'Benifit Panel' 'Stats Panel' 'Charts Panel' 'DocPanel Panel' 'Ganesh Panel';
	}

	@media screen and (max-width: 800px) {
		padding-bottom: 400px;
	}
`;

const Menu = styled(Box)`
	grid-area: Menu;
	background-color: #00001f;
	padding-top: 30px;
	padding-left: 15px;
	min-height: 95vh;

	@media screen and (max-width: 1350px) {
		padding-top: 20px;
		padding-left: 20px;
		min-height: min-content;
	}

	@media screen and (max-width: 550px) {
		padding: 5px;
		min-height: min-content;
	}
`;

const Heading = styled(Box)`
	grid-area: Heading;
	margin-top: 40px;

	@media screen and (max-width: 1350px) {
		margin-top: initial;
		margin-left: 20px;
	}

	@media screen and (max-width: 800px) {
		grid-column: 1 / span 2;
		margin-right: 20px;
	}
`;

const BenifitsWrapper = styled(Box)`
	grid-area: Benifit;
	margin-top: 40px;

	@media screen and (max-width: 1350px) {
		margin-top: initial;
		margin-left: 20px;
	}

	@media screen and (max-width: 800px) {
		grid-column: 1 / span 2;
		margin-right: 20px;
	}
`;

const Ganesh = styled(Box)`
	display: flex;
	flex-direction: column;
	grid-area: Ganesh;
	align-items: center;
	width: 100%;
	height: 100%;
	justify-content: center;

	@media screen and (max-width: 1350px) {
		margin-left: 20px;
	}

	@media screen and (max-width: 800px) {
		grid-column: 1 / span 2;
		margin-right: 20px;
	}
`;

const Stats = styled(Box)`
	grid-area: Stats;

	@media screen and (max-width: 1350px) {
		margin-left: 20px;
	}

	@media screen and (max-width: 800px) {
		grid-column: 1 / span 2;
		margin-right: 20px;
	}
`;

const WrappedButton = styled(Button)`
	height: 52px;
	background: rgb(151, 252, 228);
	border-radius: 26px;

	:hover {
		background-color: #76cfc1;
	}
`;

const ChartsPanel = styled(Box)`
	grid-area: Charts;

	@media screen and (max-width: 1350px) {
		margin-left: 20px;
	}

	@media screen and (max-width: 1200px) {
		grid-column: 1 / span 2;
		margin-right: 20px;
	}
`;

const DocPanel = styled(Box)`
	grid-area: DocPanel;

	@media screen and (max-width: 1350px) {
		margin-left: 20px;
	}

	@media screen and (max-width: 1200px) {
		grid-column: 1 / span 2;
	}

	@media screen and (max-width: 800px) {
		margin-right: 20px;
	}
`;

const Panel = styled(Box)`
	grid-area: Panel;
	margin-top: 40px;

	@media screen and (max-width: 800px) {
		position: fixed;
		bottom: 0;
		right: 1%;

		width: 98%;
	}
`;

const Image = styled.img`
	height: 160px;
	width: 100%;
	object-fit: contain;
`;

const Image2 = styled.img`
	height: 100px;
	width: 100%;
	object-fit: contain;
`;

const Dashboard: React.ComponentType = () => {
	const activePool = useAppSelector((state) => state.activePool.pool);

	const dispatch = useAppDispatch();
	const { search } = useLocation();

	// take out the strategy from the url
	const query = useMemo(() => new URLSearchParams(search), [search]);

	// dispatch the strategy to the store
	useEffect(() => {
		const strategyId = query.get('strategy') || sMMPools[0].id;
		dispatch(activePoolActions.setActivePool(strategyId));
	}, [dispatch, query]);

	if (!activePool) {
		return null;
	}

	return (
		<PageLayout>
			<GlobalStyles
				styles={{
					'html, body, #root': {
						backgroundColor: '#1A1A33',
					},
				}}
			/>

			<Menu>
				<MenuBar />
			</Menu>

			{activePool.id === 'ganesh' && (
				<Ganesh>
					<Image src={Combination} alt="pool" />
					<Gutter spacing={2} />
					<Typography variant={'h2'} fontWeight={600}>
						Introducing Ganesh
					</Typography>
					<Typography variant={'h2'} fontWeight={600}>
						on
					</Typography>
					<Gutter spacing={3} />
					<Image2 src={HyperliquidT} alt="pool" height={120} />
					<Gutter spacing={4} />
					{/*<WrappedButton
						onClick={() =>
							window.open(
								'https://app.hyperliquid.xyz/vaults/0x6fe21263c15c283063574cca8f4f02a5a11807cd',
								'_blank',
							)
						}
						variant="contained"
					>
						<Typography
							variant={'button'}
							py="6px"
							fontWeight={'bold'}
							style={{ lineHeight: '120%', color: '#000' }}
						>
							Deposit on Hyperliquid
						</Typography>
					</WrappedButton>*/}
				</Ganesh>
			)}

			{activePool.id !== 'ganesh' && (
				<Heading>
					<StrategyInfo />
				</Heading>
			)}

			{activePool.id !== 'ganesh' && (
				<BenifitsWrapper>
					<Benefits />
				</BenifitsWrapper>
			)}

			{/*{activePool.id !== 'ganesh' && (
				<Stats>
					<PersonalStats />
				</Stats>
			)}*/}

			{/*{activePool.id !== 'ganesh' && (
				<ChartsPanel>
					<Chart1 />
					<Gutter spacing={2} />
					<Chart2 />
				</ChartsPanel>
			)}*/}

			{/*{activePool.id !== 'ganesh' && (
				<DocPanel>
					<DetailPanel />
				</DocPanel>
			)}*/}

			{activePool.id !== 'ganesh' && (
				<Panel>
					<DepositWithdrawPanel />
				</Panel>
			)}
		</PageLayout>
	);
};

export { Dashboard };
