import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'hooks';
import { SMMPool, sMMPools } from 'data';
import { MenuItem } from 'pages/dashboard/components/MenuItem';
import { FlexCol } from 'components/Flex';
import { activePoolActions } from 'store/active-pool';
import { PoolDropdown } from 'pages/dashboard/components/PoolDropdown';

const Container = styled(FlexCol)`
	flex-direction: column;

	@media (max-width: 1350px) {
		flex-direction: row;
	}

	@media (max-width: 550px) {
		display: none;
	}
`;

const DropDownContainer = styled(FlexCol)`
	display: none;

	@media (max-width: 550px) {
		display: block;
	}
`;

export const MenuBar = () => {
	const dispatch = useAppDispatch();
	const activePoolState = useAppSelector((state) => state.activePool);

	const strategySelected = useCallback(
		(strategy: SMMPool['id']) => {
			dispatch(activePoolActions.setActivePool(strategy));
		},
		[dispatch],
	);

	return (
		<>
			<Container>
				{sMMPools.map((smm) => (
					<MenuItem
						key={smm.id}
						isActive={smm.id === activePoolState.pool?.id}
						smmPool={smm}
						onClick={() => {
							strategySelected(smm.id);
						}}
					/>
				))}
			</Container>
			<DropDownContainer>
				<PoolDropdown onPoolSelect={(id) => strategySelected(id)} />
			</DropDownContainer>
		</>
	);
};
