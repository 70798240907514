import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from 'hooks';
import { walletModalActions } from 'store/wallet-modal';
import { WalletTile } from 'components/WalletDialog/WalletTile';

import { wallets } from 'data';
import { Disclaimer } from 'components/WalletDialog/Disclaimer';

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
		background: '#33334A',
	},
}));

function StyledDialogTitle(props: DialogTitleProps) {
	const { children, onClose } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2, background: '#33334A' }}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
}

const WalletDialog = () => {
	const dispatch = useAppDispatch();
	const isOpen = useAppSelector((state) => state.walletModal.isOpen);
	const [checked, setChecked] = useState(false);

	const closeWalletModal = () => {
		dispatch(walletModalActions.closeWalletModal());
	};

	return (
		<StyledDialog
			onClose={closeWalletModal}
			aria-labelledby="customized-dialog-title"
			open={isOpen}
			maxWidth="xs"
		>
			<StyledDialogTitle
				id="customized-dialog-title"
				onClose={closeWalletModal}
			>
				<Typography
					sx={{
						color: 'white',
						fontSize: '1.4rem',
					}}
					fontWeight="bold"
				>
					Connect Wallet
				</Typography>
			</StyledDialogTitle>

			<DialogContent
				dividers
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
				}}
			>
				{wallets.map((wallet, index) => (
					<WalletTile
						key={wallet.connector}
						{...wallet}
						disabled={index > 0 || !checked}
					/>
				))}
				<Disclaimer checked={checked} setChecked={setChecked} />
			</DialogContent>
		</StyledDialog>
	);
};

export { WalletDialog };
