import React from 'react';
import styled from '@emotion/styled';
import { FlexCol, FlexRow } from 'components/Flex';
import { SMMPool } from 'data';
import { Typography } from '@mui/material';

const Item = styled(FlexRow)<{ isActive: boolean }>`
	width: 100%;
	min-width: 200px;
	max-width: 238px;
	border-radius: 7px;
	overflow: hidden;
	border: ${({ isActive }) =>
		isActive ? '3px solid #CFDDF8' : '1px solid #235EE1'};
	opacity: ${({ isActive }) => (isActive ? 1 : 0.8)};
	align-items: center;

	@media (max-width: 1350px) {
		height: 100%;
		max-width: 250px;
		max-height: 60px;
	}

	:hover {
		opacity: 1;
	}
`;

const Arrow = styled(FlexCol)`
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 10px solid #fff;

	@media (max-width: 1350px) {
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 10px solid #fff;
	}
`;

const ItemText = styled(FlexCol)`
	flex: 1;
	padding-left: 10px;
	justify-content: center;
	align-items: flex-start;
`;

const Image = styled.img`
	width: 60px;
	height: 50px;
	object-fit: contain;
`;

const Wrapper = styled(FlexRow)`
	margin: 0 0 10px 0;
	align-items: center;

	@media (max-width: 1350px) {
		min-width: 250px;
		flex-direction: column;
		margin: 0 10px 0 0;
	}

	@media (min-width: 1351px) {
		width: 100%;
	}

	:hover {
		cursor: pointer;
	}
`;

type Props = {
	isActive: boolean;
	smmPool: SMMPool;
	onClick: () => void;
};

export const MenuItem: React.ComponentType<Props> = ({
	isActive,
	smmPool,
	onClick,
}) => {
	return (
		<Wrapper onClick={onClick}>
			<Item isActive={isActive}>
				<Image
					src={smmPool.illustration}
					style={{ background: smmPool.backgroundColor }}
				/>
				<ItemText>
					<Typography variant={'subtitle1'} fontWeight={500}>
						{smmPool.name}
					</Typography>
				</ItemText>
			</Item>
			{isActive && <Arrow />}
		</Wrapper>
	);
};
