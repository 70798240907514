import React from 'react';
import styled from '@emotion/styled';
import Traders from 'assets/images/traders.svg';
import Project from 'assets/images/projects.svg';
import { Typography } from '@mui/material';
import { FlexCol, FlexRow } from 'components/Flex';
import { Gutter } from 'components/Gutter';

const Image = styled('img')``;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: fit-content;
	background: #00001f;
	padding: 20px 20px 100px 20px;

	@media (max-width: 500px) {
		padding: 10px 10px 50px 10px;
	}
`;

const Wrapper = styled('div')`
	position: relative;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr;
	row-gap: 20px;
	column-gap: 20px;
	width: 100%;
	// padding: 38px 122px 38px 120px;

	/*@media (max-width: 2400px) {
		padding: 43px 180px;
	}

	@media (max-width: 2000px) {
		padding: 38px 122px 38px 120px;
	}

	@media (max-width: 1400px) {
		padding: 65px 82px;
	}

	@media (max-width: 1000px) {
		grid-template-columns: 1fr;
	}

	@media (max-width: 860px) {
		padding: 30px 40px;
		grid-template-columns: 1fr;
	}

	@media (max-width: 500px) {
		grid-template-columns: 1fr;
		padding: 18px 21px;
	}*/
`;

const Card = styled('div')`
	display: grid;
	width: 100%;
	height: 100%;
	align-items: center;
	grid-template-columns: 1fr;
	column-gap: 20px;
	row-gap: 20px;
	background: #00001f;
	border: 1px solid #33334a;
	padding: 40px 32px;
	border-radius: 10px;
	grid-template-rows: 1fr auto;
	align-self: center;
	justify-content: center;

	@media (max-width: 1400px) {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr auto;
		align-self: center;
	}

	@media (max-width: 1000px) {
		grid-template-columns: 1fr;
	}

	@media (max-width: 500px) {
		padding: 40px 20px;
	}
`;

const ImageWrapper = styled(FlexCol)`
	position: relative;
	max-height: 300px;
	width: 100%;
	height: auto;
	min-width: 300px;
	align-items: center;

	@media (max-width: 1400px) {
		justify-self: center;
	}

	@media (max-width: 1000px) {
		justify-self: center;
	}

	@media (max-width: 500px) {
		justify-self: center;
	}
`;

const Col = styled(FlexCol)`
	width: 100%;
	align-items: center;

	:hover {
		cursor: pointer;
	}

	@media (max-width: 1400px) {
		justify-self: center;
		align-items: center;
	}
`;

export const Benefits = ({ location }: { location?: 'GB' }) => {
	return (
		/*<Container>
			<Gutter spacing={3} />*/
		<Wrapper>
			{!location && (
				<Card>
					<ImageWrapper>
						<Image src={Traders} alt={'Traders'} />
					</ImageWrapper>
					<Col align={'flex-start'}>
						<Gutter spacing={1.5} />
						<FlexRow align={'flex-end'}>
							<Typography
								display={'inline'}
								variant={'h5'}
								color={'#B8CDF5'}
								fontWeight={500}
							>
								Total Volume
							</Typography>
						</FlexRow>
						<Typography
							display={'inline'}
							variant={'h2'}
							color={'#B8CDF5'}
							fontWeight={600}
							textAlign={'center'}
						>
							$20.34M
						</Typography>
						<Gutter spacing={1.5} />
					</Col>
				</Card>
			)}
			<Card>
				<ImageWrapper>
					<Image src={Project} alt={'Project'} />
				</ImageWrapper>
				<Col
					align={'flex-start'}
					/*onClick={() =>
							window.open('https://twitter.com/cred_v')
						}*/
				>
					<Gutter spacing={1.5} />
					<FlexRow
						align={'flex-end'}
						wrap={'wrap'}
						style={{ flex: 1 }}
					>
						<Typography
							display={'inline'}
							variant={'h5'}
							color={'#B8CDF5'}
							fontWeight={500}
						>
							Estimated APY
						</Typography>
					</FlexRow>
					<Gutter />
					<Typography
						display={'inline'}
						variant={'h2'}
						color={'#B8CDF5'}
						fontWeight={600}
					>
						64.76%
					</Typography>
					<Gutter />
					{/*<Box>
							<StyledText variant={'caption'}>
								Only for Whitelisted User
							</StyledText>
							<Gutter gap={0.4} />
							<Image
								src={XLogo}
								alt={'Twitter'}
								width={16}
								height={16}
							/>
						</Box>*/}
					<Gutter spacing={1.5} />
				</Col>
			</Card>
		</Wrapper>
		/*<Gutter spacing={2} />
		</Container>*/
	);
};
