import React, { useEffect } from 'react';
import {
	Box,
	ClickAwayListener,
	Fade,
	Popper,
	Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import { FlexCol, FlexRow } from 'components/Flex';
import { useAppSelector } from 'hooks';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SMMPool, sMMPools } from 'data';
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

const Item = styled(FlexRow)<{ isDropdown?: boolean; isActive?: boolean }>`
	width: 100%;
	border-radius: 7px;
	overflow: hidden;
	align-items: center;
	padding: 1px;
	border: ${({ isDropdown }) =>
		isDropdown ? '1px solid #235ee1' : '0px solid #235ee1'};
	margin: ${({ isDropdown }) => (isDropdown ? 0 : '0px 0px 10px 0px')};
	background-color: ${({ isActive }) => (isActive ? '#222' : 'transparent')};

	@media (max-width: 1200px) {
		height: 100%;
		max-height: 60px;
	}

	:hover {
		cursor: pointer;
		border: ${({ isDropdown }) =>
			isDropdown ? '2px solid #235ee1' : '0px solid #235ee1'};
		padding: ${({ isDropdown }) => (isDropdown ? 0 : '1px')};
		background-color: #333;
	}
`;

const ItemText = styled(FlexCol)`
	flex: 1;
	padding-left: 10px;
	justify-content: center;
	align-items: flex-start;
`;

const Image = styled.img`
	width: 60px;
	height: 50px;
	object-fit: contain;
`;

const DownKey = styled(KeyboardArrowDownIcon)`
	color: #ffffff;
`;

const Option = styled(FlexCol)<{ width: number }>`
	flex: 1;
	width: ${({ width }) => width}px;
	padding: 5px 5px 0 5px;
	border-radius: 5px;
	background-color: #22221f;
	margin: 5px 10px;

	@media (min-width: 550px) {
		display: none;
	}
`;

type PoolDropdownProps = {
	onPoolSelect: (poolId: string) => void;
};

export const PoolDropdown: React.ComponentType<PoolDropdownProps> = ({
	onPoolSelect,
}) => {
	const [width, setWidth] = React.useState(300);
	const activePoolState = useAppSelector((state) => state.activePool);

	useEffect(() => {
		setWidth(window.innerWidth - 20);
		window.addEventListener('resize', () => {
			setWidth(window.innerWidth - 20);
		});

		return () => {
			window.removeEventListener('resize', () => {});
		};
	}, []);

	const RenderPoolMenu = ({
		pool,
		isDownButton,
		...props
	}: {
		pool?: SMMPool;
		isDownButton?: boolean;
		isActive?: boolean;
	}) => {
		if (!pool) return <></>;

		return (
			<Item isDropdown={isDownButton} {...props}>
				<Image
					src={pool?.illustration}
					style={{
						background: pool?.backgroundColor,
					}}
				/>
				<FlexRow
					justify={'space-between'}
					style={{ flex: 1, paddingRight: 10 }}
				>
					<ItemText>
						<Typography variant={'subtitle1'} fontWeight={500}>
							{pool?.name}
						</Typography>
					</ItemText>
					{isDownButton && <DownKey />}
				</FlexRow>
			</Item>
		);
	};

	return (
		<Box>
			<PopupState
				variant="popper"
				popupId="demo-popup-popper"
				disableAutoFocus={true}
			>
				{(popupState) => (
					<ClickAwayListener onClickAway={() => popupState.close()}>
						<div>
							<div {...bindToggle(popupState)}>
								<RenderPoolMenu
									pool={activePoolState.pool}
									isDownButton={true}
								/>
							</div>
							<Popper
								{...bindPopper(popupState)}
								transition
								placement="bottom-end"
							>
								{({ TransitionProps }) => (
									<>
										<Fade
											{...TransitionProps}
											timeout={350}
										>
											<Option width={width}>
												{sMMPools.map((smm) => (
													<div
														key={smm.id}
														onClick={() => {
															onPoolSelect(
																smm.id,
															);
															popupState.close();
														}}
														style={{
															width: '100%',
														}}
													>
														<RenderPoolMenu
															pool={smm}
															isActive={
																smm.id ===
																activePoolState
																	.pool?.id
															}
														/>
													</div>
												))}
											</Option>
										</Fade>
									</>
								)}
							</Popper>
						</div>
					</ClickAwayListener>
				)}
			</PopupState>
		</Box>
	);
};
