import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { PoolItem } from './pool-item';
import { FlexCol } from 'components/Flex';
import { Gutter } from 'components/Gutter';
import { Link } from 'react-router-dom';
import { SMMPool, sMMPools } from 'data';

const Container = styled.div`
	display: flex;
	width: 100%;
	height: 80vh;
	padding: 10px;
	justify-content: center;
	align-items: center;
`;

const GridRow = styled.div`
	display: grid;
	max-width: 100%;
	grid-template-columns: 1fr 1fr 0.7fr;
	grid-gap: 10px;
	overflow: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;

	@media (max-width: 850px) {
		grid-template-columns: 1fr 1fr;
	}
`;

const Col = styled(FlexCol)`
	max-width: 100%;
	align-items: center;
`;

const Box = styled(FlexCol)`
	width: 100%;
	height: 100%;
	border: 1px solid #4d4d60;
	border-radius: 12px;
	justify-content: center;
	align-items: center;
	padding: 10px 5px;
`;

const Box1 = styled(Box)`
	@media (max-width: 850px) {
		display: none;
	}
`;

const Box2 = styled(Box)`
	@media (min-width: 850px) {
		display: none;
	}
`;

const PoolSelection: React.ComponentType = () => {
	const RenderPool = useCallback(({ ...pool }: SMMPool) => {
		return (
			<Link
				style={{ textDecoration: 'none' }}
				to={`main?strategy=${pool.id}`}
			>
				<PoolItem {...pool} />
			</Link>
		);
	}, []);

	return (
		<Container>
			<Col>
				<Typography
					variant={'h6'}
					color={'#719DEC'}
					style={{ alignSelf: 'flex-start' }}
				>
					Select an vault
				</Typography>
				<Gutter />
				<GridRow>
					{sMMPools.map((pool) => (
						<RenderPool key={pool.id} {...pool} />
					))}
					<Box1>
						<Typography variant={'h6'} color={'#CCCCD1'}>
							More coming soon...
						</Typography>
					</Box1>
				</GridRow>
				<Gutter />
				<Box2>
					<Typography variant={'h6'} color={'#CCCCD1'}>
						More coming soon...
					</Typography>
				</Box2>
			</Col>
		</Container>
	);
};

export { PoolSelection };
