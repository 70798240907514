import React from 'react';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { Gutter } from 'components/Gutter';
import { SMMPool } from 'data';

const Image = styled.img`
	height: 180px;
	width: 100%;
	object-fit: contain;
`;

const StyledCard = styled(Card)`
	position: relative;
	max-width: 300px;
	min-width: 250px;
	background: #1a1a33;
	border: 1px solid #235ee1;
	box-shadow: 0 4px 4px #000000;
	border-radius: 12px;
	height: 100%;

	:hover {
		opacity: 0.8;
	}
`;

export const PoolItem = ({
	illustration,
	backgroundColor,
	name,
	description,
}: SMMPool) => {
	return (
		<StyledCard>
			<CardActionArea>
				<Image
					src={illustration}
					alt="pool"
					style={{ background: backgroundColor }}
				/>
				<CardContent>
					<Typography
						gutterBottom
						variant="h6"
						component="div"
						color={'#fff'}
						fontWeight={500}
					>
						{name}
					</Typography>
					<Gutter spacing={0.7} />
					<Typography
						variant={'body2'}
						color={'#B3B3BB'}
						style={{
							lineHeight: '140%',
							fontFeatureSettings: 'calt off',
						}}
					>
						{description}
					</Typography>
					<Gutter spacing={0.8} />
				</CardContent>
			</CardActionArea>
		</StyledCard>
	);
};
