import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, OutlinedInput, Typography } from '@mui/material';
import { AssetDropdownSelect } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ethers } from 'ethers';
import { roundTo } from 'utils/roundTo';
import { depositActions } from 'store/deposit';
import { useWeb3React } from '@web3-react/core';
import { walletModalActions } from 'store/wallet-modal';
import { toast } from 'react-toastify';

const inputStyles = {
	'&, &:hover': {
		outline: '1px solid #235EE1',
	},
	color: 'white',
	fontWeight: 500,
	'& .MuiOutlinedInput-root': {
		fontWeight: 500,
	},
	'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
		display: 'none',
	},
};

const DepositPanel: React.ComponentType = () => {
	const { account, library } = useWeb3React();
	const dispatch = useAppDispatch();
	const depositing = useAppSelector((state) => state.deposits.depositing);
	const walletBalances = useAppSelector(
		(state) => state.walletBalances.balances,
	);
	const selectedAsset = useAppSelector((state) => state.activePool.token);

	const assetBalance = useMemo(() => {
		if (!walletBalances || !selectedAsset) {
			return 0;
		}

		const balance = walletBalances.find(
			(b) => b.token === selectedAsset.symbol,
		);

		if (!balance) {
			return 0;
		}

		return balance.balance;
	}, [selectedAsset, walletBalances]);

	const [depositAmount, setDepositAmount] = useState('');

	const isFormValid = useMemo(() => {
		let cleanedAmount = '0';
		cleanedAmount = Number(parseFloat(depositAmount)).toString();

		if (!cleanedAmount || cleanedAmount === 'NaN' || cleanedAmount === '0')
			return false;

		const tentativeDepositValue = ethers.utils.parseUnits(
			cleanedAmount || '0',
			selectedAsset?.decimals || 18,
		);

		return tentativeDepositValue.lte(assetBalance);
	}, [assetBalance, depositAmount, selectedAsset]);

	const triggerDeposit = useCallback(() => {
		if (!selectedAsset) {
			return;
		}

		if (!account) {
			dispatch(walletModalActions.openWalletModal());
			return;
		}

		dispatch(
			depositActions.deposit({
				account,
				library,
				amount: depositAmount,
				asset: selectedAsset,
			}),
		);
	}, [account, depositAmount, dispatch, library, selectedAsset]);

	const isDepositBtnActive = useMemo(() => {
		return account ? !isFormValid || depositing : false;
	}, [account, depositing, isFormValid]);

	const buttonLabel = useMemo(() => {
		if (!account) return 'Connect Wallet';

		if (depositing) return 'Depositing...';

		return 'Deposit';
	}, [account, depositing]);

	const endEdornment = useMemo(() => {
		return null;

		/*return (
			<Box>
				<Typography fontSize={16} fontWeight={500}>
					$0.00
				</Typography>
			</Box>
		);*/
	}, []);

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				<Typography color="#E6E6E9">Select asset</Typography>
				<Box display="flex" alignItems="center">
					<Typography color="#E6E6E9" fontWeight={500} fontSize={12}>
						{roundTo(
							parseFloat(
								ethers.utils.formatUnits(
									assetBalance,
									selectedAsset?.decimals || 18,
								),
							),
							4,
						)}{' '}
						{selectedAsset?.symbol}
					</Typography>
					<Box width={4} />
					<img src="/icons/wallet.png" />
				</Box>
			</Box>
			<Box height={8} />
			<AssetDropdownSelect />

			<Box height={16} />

			<Typography color="#E6E6E9">Amount</Typography>
			<Box height={8} />
			<OutlinedInput
				disabled={depositing}
				placeholder="0.00"
				value={depositAmount}
				onChange={(event) => {
					setDepositAmount(event.target.value);
				}}
				type="number"
				fullWidth
				margin="dense"
				sx={inputStyles}
				endAdornment={endEdornment}
			/>

			<Box height={28} />

			<Button
				onClick={() => {
					toast(
						'Whitelisted access only: Contact info@vcred.trade if interested',
						{
							type: 'info',
						},
					);
				}}
				//onClick={triggerDeposit}
				// disabled={isDepositBtnActive}
				sx={{
					height: 52,
				}}
				variant="contained"
				fullWidth
				color="primary"
			>
				<Typography
					variant={'button'}
					fontWeight="bold"
					py="6px"
					style={{ lineHeight: '120%' }}
				>
					{buttonLabel}
				</Typography>
			</Button>
		</Box>
	);
};

export { DepositPanel };
